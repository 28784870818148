export enum AnalysisPanelTabs {
    ThoughtProcessTab = "thoughtProcess",
    SupportingContentTab = "supportingContent"
}

// CONFIGURACIÓN: Ocultar visualizador de PDFs
// export enum AnalysisPanelTabs {
//     ThoughtProcessTab = "thoughtProcess",
//     SupportingContentTab = "supportingContent",
//     CitationTab = "citation"
// }
